<template>
    <div :class="['popup-overlay', { active: isVisible }]" @click.self="closePopup">
        <div class="popup-content" :style="{ width:width, maxWidth:maxWidth}">
            <span class="popup-close" @click="closePopup">&times;</span>
            <h2>{{ title }}</h2>
            <div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Popup",
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Popup Title'
        },
        width: {
            type: String,
            default: '80%'
        },
        maxWidth: {
            type: String,
            default: '500px'
        }
    },
    methods: {
        closePopup() {
            this.$emit('close');
        }
    }
};
</script>

<style scoped>
/* Popup container */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}

.popup-overlay.active {
    opacity: 1;
    pointer-events: auto;
}

.popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: slide-down 0.3s ease;
}

.popup-content h2 {
    margin-top: 0;
}

.popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}

@keyframes slide-down {
    from {
        transform: translateY(-30px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
</style>