<template>
  <div class="common_sub_container">
    <Loader v-if="loader.is_loading" :text="loader.text" />
    <div class="container">
      <ActionButton @handle-click="goBack()">
        <i class="material-icons">arrow_back</i>
      </ActionButton>
      <h2 class="centered-heading">INVOICE RECONCILIATION PROCESS</h2>
    </div>
    <section>
      <div v-if="reconciliation_task">
        <div class="table-responsive details-table">
          <table class="borderless-table">
            <tbody>
              <tr>
                <td><b>Carrier:</b></td>
                <td>{{ reconciliation_task.carrier }}</td>
                <td><b>Uploaded By:</b></td>
                <td>{{ reconciliation_task.uploaded_by.name }}</td>
                <td><b>Files:</b></td>
                <td>
                  <p v-for="(file, f) in reconciliation_task.invoice_files">
                    {{ file.file_name }}
                  </p>
                </td>
              </tr>
              <tr>
                <td><b>Status:</b></td>
                <td>
                  <Badge :type="reconciliation_task.status == 'reconciled' ? 'success' : 'info'">{{
                    capitalizeFirstLetter(reconciliation_task.status) }} </Badge>
                </td>
                <td><b>Uploaded Date:</b></td>
                <td>{{ reconciliation_task.created_at | formatDate }}</td>
                <td><b>Uploaded Time:</b></td>
                <td>{{ reconciliation_task.created_at | formatTime }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <form @submit.prevent="reconcileInvoice">
          <div class="invoice-container">
            <div class="tabs">
              <div class="tab" :class="{ active: activeTab === 'matched' }" @click="activeTab = 'matched'">
                {{ `Matched Bookings (${reconciliation_task.matched_invoice_data.length})` }}
              </div>
              <div class="tab" :class="{ active: activeTab === 'multiple' }" @click="activeTab = 'multiple'">
                {{ `Multiple Lines (${reconciliation_task.same_invoice_duplicate_data.length})` }}
              </div>
              <div class="tab" :class="{ active: activeTab === 'duplicated' }" @click="activeTab = 'duplicated'">
                {{ `Duplicated with previous Invoices (${reconciliation_task.duplicated_invoice_data.length})` }}
              </div>
              <div class="tab" :class="{ active: activeTab === 'unmatched' }" @click="activeTab = 'unmatched'">
                {{ `Unmatched Bookings (${reconciliation_task.unmatched_invoice_data.length})` }}
              </div>
            </div>
            <div class="content">
              <div v-show="activeTab === 'matched'">
                <!-- DHL -->
                <DhlInvoiceDataTable v-if="reconciliation_task.carrier === 'DHL'"
                  :invoice_data="reconciliation_task.matched_invoice_data" :checked_invoices="selected_invoices"
                  @update-selected="handleSelected" invoice_type="matched" @manage-comment-center="handleCommentCenter"
                  :error_invoices="error_invoices" :error_status="error_status"
                  @manage-user-selection="updateUserSelection" :status="reconciliation_task.status" />

                <!-- STC -->
                <STCInvoiceDataTable v-if="reconciliation_task.carrier === 'STC'"
                  :invoice_data="reconciliation_task.matched_invoice_data" :checked_invoices="selected_invoices"
                  @update-selected="handleSelected" invoice_type="matched" @manage-comment-center="handleCommentCenter"
                  :error_invoices="error_invoices" :error_status="error_status"
                  @manage-user-selection="updateUserSelection" :status="reconciliation_task.status" />

                <!-- TGE -->
                <TgeInvoiceDataTable v-if="reconciliation_task.carrier === 'TGE'"
                  :invoice_data="reconciliation_task.matched_invoice_data" :checked_invoices="selected_invoices"
                  @update-selected="handleSelected" invoice_type="matched" @manage-comment-center="handleCommentCenter"
                  :error_invoices="error_invoices" :error_status="error_status"
                  @manage-user-selection="updateUserSelection" :status="reconciliation_task.status" />

              </div>
              <div v-show="activeTab === 'multiple'">
                <!-- DHL -->
                <DhlInvoiceDataTable v-if="reconciliation_task.carrier === 'DHL'"
                  :invoice_data="reconciliation_task.same_invoice_duplicate_data" :checked_invoices="selected_invoices"
                  @update-selected="handleSelected" invoice_type="multiple" @manage-comment-center="handleCommentCenter"
                  :error_invoices="error_invoices" :error_status="error_status"
                  @manage-user-selection="updateUserSelection" @manage-cost-center="handleCostCenterManager"
                  :status="reconciliation_task.status" />

                <!-- STC -->
                <STCInvoiceDataTable v-if="reconciliation_task.carrier === 'STC'"
                  :invoice_data="reconciliation_task.same_invoice_duplicate_data" :checked_invoices="selected_invoices"
                  @update-selected="handleSelected" invoice_type="multiple" @manage-comment-center="handleCommentCenter"
                  :error_invoices="error_invoices" :error_status="error_status"
                  @manage-user-selection="updateUserSelection" @manage-cost-center="handleCostCenterManager"
                  :status="reconciliation_task.status" />

                <!-- TGE -->
                <TgeInvoiceDataTable v-if="reconciliation_task.carrier === 'TGE'"
                  :invoice_data="reconciliation_task.same_invoice_duplicate_data" :checked_invoices="selected_invoices"
                  @update-selected="handleSelected" invoice_type="multiple" @manage-comment-center="handleCommentCenter"
                  :error_invoices="error_invoices" :error_status="error_status"
                  @manage-user-selection="updateUserSelection" @manage-cost-center="handleCostCenterManager"
                  :status="reconciliation_task.status" />

              </div>
              <div v-show="activeTab === 'duplicated'">
                <!-- DHL -->
                <DhlInvoiceDataTable v-if="reconciliation_task.carrier === 'DHL'"
                  :invoice_data="reconciliation_task.duplicated_invoice_data" :checked_invoices="selected_invoices"
                  @update-selected="handleSelected" invoice_type="duplicated"
                  @manage-comment-center="handleCommentCenter" :error_invoices="error_invoices"
                  :error_status="error_status" @manage-user-selection="updateUserSelection"
                  @manage-cost-center="handleCostCenterManager" @show-duplicated-history="handleDuplicatedHistory"
                  :status="reconciliation_task.status" />

                <!-- STC -->
                <STCInvoiceDataTable v-if="reconciliation_task.carrier === 'STC'"
                  :invoice_data="reconciliation_task.duplicated_invoice_data" :checked_invoices="selected_invoices"
                  @update-selected="handleSelected" invoice_type="duplicated"
                  @manage-comment-center="handleCommentCenter" :error_invoices="error_invoices"
                  :error_status="error_status" @manage-user-selection="updateUserSelection"
                  @manage-cost-center="handleCostCenterManager" @show-duplicated-history="handleDuplicatedHistory"
                  :status="reconciliation_task.status" />

                <!-- TGE -->
                <TgeInvoiceDataTable v-if="reconciliation_task.carrier === 'TGE'"
                  :invoice_data="reconciliation_task.duplicated_invoice_data" :checked_invoices="selected_invoices"
                  @update-selected="handleSelected" invoice_type="duplicated"
                  @manage-comment-center="handleCommentCenter" :error_invoices="error_invoices"
                  :error_status="error_status" @manage-user-selection="updateUserSelection"
                  @manage-cost-center="handleCostCenterManager" @show-duplicated-history="handleDuplicatedHistory"
                  :status="reconciliation_task.status" />

              </div>
              <div v-show="activeTab === 'unmatched'">
                <!-- DHL -->
                <DhlInvoiceDataTable v-if="reconciliation_task.carrier === 'DHL'"
                  :invoice_data="reconciliation_task.unmatched_invoice_data" :checked_invoices="selected_invoices"
                  @update-selected="handleSelected" invoice_type="unmatched"
                  @manage-cost-center="handleCostCenterManager" @manage-comment-center="handleCommentCenter"
                  :error_invoices="error_invoices" :error_status="error_status"
                  @manage-user-selection="updateUserSelection" :status="reconciliation_task.status" />

                <!-- STC -->
                <STCInvoiceDataTable v-if="reconciliation_task.carrier === 'STC'"
                  :invoice_data="reconciliation_task.unmatched_invoice_data" :checked_invoices="selected_invoices"
                  @update-selected="handleSelected" invoice_type="unmatched"
                  @manage-cost-center="handleCostCenterManager" @manage-comment-center="handleCommentCenter"
                  :error_invoices="error_invoices" :error_status="error_status"
                  @manage-user-selection="updateUserSelection" :status="reconciliation_task.status" />

                <!-- TGE -->
                <TgeInvoiceDataTable v-if="reconciliation_task.carrier === 'TGE'"
                  :invoice_data="reconciliation_task.unmatched_invoice_data" :checked_invoices="selected_invoices"
                  @update-selected="handleSelected" invoice_type="unmatched"
                  @manage-cost-center="handleCostCenterManager" @manage-comment-center="handleCommentCenter"
                  :error_invoices="error_invoices" :error_status="error_status"
                  @manage-user-selection="updateUserSelection" :status="reconciliation_task.status" />
              </div>
            </div>
          </div>

          <div class="custom-btn-wrapper">
            <!-- <ActionButton
              v-if="reconciliation_task.reconciled_invoice_data.length !== reconciliation_task.uploaded_invoice_count"
              triggerType="submit" type="system" :className="[]" :isDisabled="button.disabled">
              {{ button.text.toUpperCase() }}
            </ActionButton> -->

            <ActionButton v-if="reconciliation_task.status !== 'reconciled'" triggerType="submit" type="system"
              :className="[]" :isDisabled="button.disabled">
              {{ button.text.toUpperCase() }}
            </ActionButton>


            <ActionButton @handle-click="generateApprovalFile"
              v-if="reconciliation_task.reconciled_invoice_data.length > 0" triggerType="button" type="system"
              :className="[]" :isDisabled="button.disabled">
              {{ generate_report_button.text.toUpperCase() }}
            </ActionButton>
          </div>
        </form>
      </div>
    </section>
    <!-- popups -->
    <Popup v-if="cost_center_data" :isVisible="popups.cost_center_popup.show" :title="popups.cost_center_popup.title"
      @close="hideCostCenterManager()" width="100%" maxWidth="700px">
      <CostCenterManager :data="cost_center_data" @update-cost-center-data="updateCostCenter" />
    </Popup>

    <Popup v-if="comment_data" :isVisible="popups.comment_popup.show" :title="popups.comment_popup.title"
      @close="hideCommentForm()" width="100%" maxWidth="700px">
      <CommentForm :data="comment_data" @update-comment-data="updateComment" :maxlength="250" />
    </Popup>

    <Popup v-if="duplicated_history_data" :isVisible="popups.duplicated_history_popup.show"
      :title="popups.duplicated_history_popup.title" @close="hideDuplicatedHistory()" width="100%" maxWidth="1200px">

      <DuplicatedBookingHistory v-if="reconciliation_task.carrier === 'DHL'" :history_data="duplicated_history_data.duplicated_invoices" />

      <STCDuplicatedBookingHistory v-if="reconciliation_task.carrier === 'STC'" :history_data="duplicated_history_data.duplicated_invoices" />

    </Popup>

    <div ref="download" style="display: none;"></div>
  </div>
</template>

<script>
import moment from "moment";
import DhlInvoiceDataTable from "./DhlInvoiceDataTable.vue";
import STCInvoiceDataTable from "./STCInvoiceDataTable.vue";
import TgeInvoiceDataTable from "./TgeInvoiceDataTable.vue";
import DuplicatedSTCInvoiceDataTable from "./DuplicatedSTCInvoiceDataTable.vue";
import Badge from "../components/Badge.vue";
import ActionButton from "../components/ActionButton.vue";
import Loader from "../components/Loader.vue";
import Popup from "../components/Popup.vue";
import CostCenterManager from "../components/CostCenterManager.vue";
import CommentForm from "../components/CommentForm.vue";
import DuplicatedBookingHistory from "./DuplicatedBookingHistory.vue";
import STCDuplicatedBookingHistory from "./STCDuplicatedBookingHistory.vue";

import { getPreviousRoute } from '../../router.js';

export default {
  name: "InvoiceReconciliationProcess",
  components: {
    DhlInvoiceDataTable,
    STCInvoiceDataTable,
    DuplicatedSTCInvoiceDataTable,
    TgeInvoiceDataTable,
    Badge,
    ActionButton,
    Loader,
    Popup,
    CostCenterManager,
    CommentForm,
    DuplicatedBookingHistory,
    STCDuplicatedBookingHistory,
  },
  filters: {
    formatDate(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('YYYY/MM/DD');
    },
    formatTime(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('HH:mm');
    }
  },
  data() {
    return {
      loading: false,
      showPopup: false,
      reconciliation_task: null,
      api_url: process.env.VUE_APP_API_URL,
      activeTab: 'matched',
      button: {
        text: 'Reconcile',
        disabled: false
      },
      generate_report_button: {
        text: 'Generate Payment Approval File',
        disabled: false
      },
      loader: {
        text: "",
        is_loading: false
      },
      selected_invoices: {
        matched: [],
        unmatched: [],
        multiple: [],
        duplicated: []
      },
      reconciliation_data: [],
      error_invoices: [],
      error_status: false,
      popups: {
        cost_center_popup: {
          show: false,
          title: "Cost Center Manager"
        },
        comment_popup: {
          show: false,
          title: "Reconciliation Comment"
        },
        duplicated_history_popup: {
          show: false,
          title: "Duplicated Booking History"
        }
      },
      cost_center_data: null,
      comment_data: null,
      duplicated_history_data: null,
    };
  },
  async created() {
    this.$emit("breadCrumbData", ["Invoice Check", "Invoice Reconciliation", "Invoice Reconciliation Process"]);

    if (this.$route.params.id == undefined) {
      this.$router.push({ name: 'InvoiceReconciliation' });
    }

    await this.getInvoiceData(this.$route.params.id);

  },
  methods: {
    async getInvoiceData(id) {

      this.loader.is_loading = true;
      this.loader.text = "Invoice Data loading...";

      await this.axios
        .get(`/api/invoice-reconciliation/task/${id}`)
        .then(response => {

          const data = response.data;

          if (!data.reconciliation_task) {
            this.toast.error(`Invoice Reconciliation Task Not Found`);

            this.$router.push({ name: 'InvoiceReconciliation' });
          }

          this.reconciliation_task = data.reconciliation_task;

        })
        .catch(error => {
          console.log(error);
        });

      this.loader.is_loading = false;
      this.loader.text = "";
    },
    goBack() {

      if (getPreviousRoute().name === 'ReconciliationHistory') {
        this.$router.push({ name: 'ReconciliationHistory' });

        return;
      }

      this.$router.push({ name: 'InvoiceReconciliation' });
    },
    capitalizeFirstLetter(string) {
      if (!string) return string;

      return string
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    },
    handleSelected(data) {
      this.selected_invoices[data['type']] = data['selected'];
    },
    hideCostCenterManager() {
      this.cost_center_data = null;
      this.popups.cost_center_popup.show = false;
      this.popups.cost_center_popup.title = `Cost Center Manager`;
    },
    handleCostCenterManager(invoice) {

      this.cost_center_data = {
        id: invoice.id,
        custom_code: invoice.custom_code,
        custom_code_value: invoice.custom_code_value,
        invoice_type: invoice.invoice_type,
      };

      this.popups.cost_center_popup.title = `Cost Center Manager (${invoice.shipment_number})`;
      this.popups.cost_center_popup.show = true;

    },
    updateCostCenter(data) {
      const { id, custom_code, custom_code_value, invoice_type } = data;

      var key = `${invoice_type}_invoice_data`;

      if (invoice_type === 'multiple') {
        key = 'same_invoice_duplicate_data';
      }

      if (custom_code && custom_code_value) {
        this.reconciliation_task[key] = this.reconciliation_task[key].map((invoice) => {
          if (invoice.id === id) {
            return {
              ...invoice,
              custom_code: custom_code,
              custom_code_value: custom_code_value
            };
          }
          return invoice;
        });

        this.hideCostCenterManager();
      }
    },
    hideCommentForm() {
      this.comment_data = null;
      this.popups.comment_popup.show = false;
      this.popups.comment_popup.title = `Reconciliation Comment`;
    },
    handleCommentCenter(invoice) {
      this.comment_data = {
        id: invoice.id,
        comment: invoice.reconciliation_comment || '',
        invoice_type: invoice.invoice_type,
      };

      this.popups.comment_popup.title = `${(invoice.reconciliation_comment == '' || invoice.reconciliation_comment == null) ? 'Add' : 'Edit'} ${this.popups.comment_popup.title} (${invoice.shipment_number})`;
      this.popups.comment_popup.show = true;
    },
    handleDuplicatedHistory(invoice) {
      this.duplicated_history_data = invoice;
      this.popups.duplicated_history_popup.title = `${this.popups.duplicated_history_popup.title} (${invoice.shipment_number})`;
      this.popups.duplicated_history_popup.show = true;
    },
    hideDuplicatedHistory() {
      this.duplicated_history_data = null;
      this.popups.duplicated_history_popup.show = false;
      this.popups.duplicated_history_popup.title = `Duplicated Booking History`;
    },
    updateComment(data) {
      const { id, comment, invoice_type } = data;

      var key = `${invoice_type}_invoice_data`;

      if (invoice_type === 'multiple') {
        key = 'same_invoice_duplicate_data';
      }

      if (comment) {
        this.reconciliation_task[key] = this.reconciliation_task[key].map((invoice) => {
          if (invoice.id === id) {
            return {
              ...invoice,
              reconciliation_comment: comment,
            };
          }
          return invoice;
        });

        this.hideCommentForm();
      }
    },
    updateUserSelection(data) {
      const { user_selection_data, invoice, invoice_type } = data;

      var key = `${invoice_type}_invoice_data`;

      if (invoice_type === 'multiple') {
        key = 'same_invoice_duplicate_data';
      }

      this.reconciliation_task[key] = this.reconciliation_task[key].map((inv) => {
        if (inv.id === invoice.id) {
          return {
            ...inv,
            is_included: user_selection_data.value,
            booking: this.bookingDataUpdate(user_selection_data, invoice, invoice_type),
          }
        };

        return inv;
      });

    },
    bookingDataUpdate(user_selection_data, invoice) {

      if (invoice.booking) {

        var total_reconciled_cost = 0;
        var initial_cost = 0;
        var booking_cost_total_reconciled_cost = 0;

        if (invoice.booking.is_reconciled) {
          total_reconciled_cost = invoice.booking.total_reconciled_cost;
          initial_cost = invoice.booking.initial_cost || invoice.booking.total_reconciled_cost;
        }

        if (user_selection_data.value == 1) {

          var amount = 0;

          if (this.reconciliation_task.carrier === 'DHL') {
            amount = invoice.total_amount_excl_vat;
          } else if (this.reconciliation_task.carrier === 'STC') {
            amount = invoice.invoice_value_ex_gst;
          } else if (this.reconciliation_task.carrier === 'TGE') {
            amount = invoice.total_amount;
          }

          booking_cost_total_reconciled_cost = Number(total_reconciled_cost) + Number(amount);
        } else {
          booking_cost_total_reconciled_cost = initial_cost;
        }

        return {
          ...invoice.booking,
          initial_cost: initial_cost,
          total_reconciled_cost: booking_cost_total_reconciled_cost
        }
      }
    },
    getInvoiceReferenceNo(invoice) {
      const { carrier } = this.reconciliation_task;

      if (carrier == 'DHL') {
        return invoice.shipment_number;
      } else if (carrier == 'STC') {
        return this.formatSTCBookingRef(invoice.airway_bill_no, invoice.job_no);
      } else if (carrier == 'TGE') {
        return invoice.connote_no;
      } else {
        return '';
      }
    },
    formatSTCBookingRef(airwayBillNo, jobNo) {
      if (!airwayBillNo && !jobNo) {
        return '';
      } else if (!airwayBillNo) {
        return jobNo;
      } else if (!jobNo) {
        return airwayBillNo;
      } else if (airwayBillNo === jobNo) {
        return airwayBillNo;
      } else {
        return `${airwayBillNo} / ${jobNo}`;
      }
    },
    async reconcileInvoice() {

      try {

        this.error_invoices = [];
        this.error_status = false;

        const payload = {
          id: this.reconciliation_task.id,
          invoices: []
        };

        const selected_invoices_count = Object.values(this.selected_invoices).reduce((total, invoices) => total + invoices.length, 0);

        if (selected_invoices_count < 1) {
          this.toast.error(`Please select one or more bookings for reconciliation.`);
          return;
        }

        // check invoice data
        const checkInvoice = (invoice) => {
          const { is_included, reconciliation_comment, custom_code, custom_code_value, is_booking_match, is_invoice_values_matched, } = invoice;

          if (is_included == null) {
            this.toast.error(`Please decide to either Include or Drop the booking ${this.getInvoiceReferenceNo(invoice)} (AWB).`);
            return false;
          }

          if (is_booking_match) {
            if ((invoice.is_included && invoice.is_included != 0) && (!is_invoice_values_matched && (!reconciliation_comment || reconciliation_comment.trim() === ''))) {
              this.toast.error(`Please provide a reconciliation comment for booking ${this.getInvoiceReferenceNo(invoice)} (AWB) due to a invoice value mismatch.`);
              return false;
            }
          } else {
            if ((invoice.is_included && invoice.is_included != 0) && ((!custom_code || custom_code.trim() === '') || (!custom_code_value || custom_code_value.trim() === ''))) {
              this.toast.error(`Please provide Cost Center for ${this.getInvoiceReferenceNo(invoice)} (AWB) as the booking was not found in the system.`);
              return false;
            }
            if ((invoice.is_included && invoice.is_included != 0) && (!reconciliation_comment || reconciliation_comment.trim() === '')) {
              this.toast.error(`Please provide a reconciliation comment for booking ${this.getInvoiceReferenceNo(invoice)} (AWB) as the booking was not found in the system.`);
              return false;
            }
          }

          return true;
        };

        // process invoice data
        const processInvoices = (invoices, invoiceData, tab, callback) => {
          for (const id of invoices) {
            const invoice = invoiceData.find((inv) => inv.id === id);

            if (invoice) {

              if (!checkInvoice(invoice)) {
                this.error_invoices.push(invoice.id);
                this.error_status = true;
                payload.invoices = [];
                this.activeTab = tab;
                return false;
              }

              const invoiceData = {
                id: invoice.id,
                data: {
                  reconciliation_comment: invoice.reconciliation_comment || null,
                  is_included: invoice.is_included,
                  ...(invoice.is_booking_match == 0 ? {
                    custom_code: invoice.custom_code,
                    custom_code_value: invoice.custom_code_value
                  } : {})
                }
              };

              payload.invoices.push(invoiceData);
            }
          }

          return true;
        };


        const invoiceTypes = [
          { selected: this.selected_invoices.matched, taskData: this.reconciliation_task.matched_invoice_data, type: 'matched' },
          { selected: this.selected_invoices.multiple, taskData: this.reconciliation_task.same_invoice_duplicate_data, type: 'multiple' },
          { selected: this.selected_invoices.duplicated, taskData: this.reconciliation_task.duplicated_invoice_data, type: 'duplicated' },
          { selected: this.selected_invoices.unmatched, taskData: this.reconciliation_task.unmatched_invoice_data, type: 'unmatched' }
        ];

        for (const { selected, taskData, type } of invoiceTypes) {
          if (!processInvoices(selected, taskData, type)) {
            return;
          }
        }

        if (payload.invoices.length === 0) {
          return;
        }

        this.button.disabled = true;

        this.loader.text = "Bookings Reconciling...";
        this.loader.is_loading = true;

        await this.axios
          .post("/api/invoice-reconciliation/submit", payload)
          .then(async response => {
            const res = response.data;

            this.resetSelectedInvoices();

            this.toast.success(res.msg);

            await this.getInvoiceData(this.$route.params.id);

            this.$emit('clear-selected');

            // this.$router.push({ name: 'InvoiceReconciliation' });

          })
          .catch(error => {
            throw error;
          });

      } catch (error) {
        console.error(error);
      } finally {
        // this.button.text = 'Upload';
        this.button.disabled = false;

        this.loader.text = "";
        this.loader.is_loading = false;
      }

    },
    resetSelectedInvoices() {
      this.selected_invoices = {
        matched: [],
        unmatched: []
      };
    },
    generateApprovalFile() {

      try {

        let method = "POST";

        let form = document.createElement("form");
        form.setAttribute("method", method);
        form.setAttribute(
          "action",
          this.api_url +
          "/api/invoice-reconciliation/generate?token=" +
          localStorage.token
        );

        let input = document.createElement("input");
        input.type = "hidden";
        input.name = "task_id";
        input.value = this.reconciliation_task.encrypted_id;
        form.appendChild(input);

        document.body.appendChild(form);

        this.generate_report_button.disabled = true;

        this.$refs.download.appendChild(form);
        form.submit();
        this.$refs.download.removeChild(form);

      } catch (error) {
        console.error("An error occurred while generating the approval file:", error);
        this.generate_report_button.disabled = false;
      } finally {
        this.generate_report_button.disabled = false;
      }
    }

  }
};
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
}

.centered-heading {
  flex-grow: 1;
  text-align: center;
  margin: 0;
}

// tabs
.tabs {
  display: flex;
  cursor: pointer;
}

.tab {
  padding: 10px 20px;
  border: 0.5px solid rgba(128, 128, 128, 0.171);
  border-bottom: none;
  background: #f9f9f9;
  transition: background-color 0.3s;
}

.tab:hover {
  background: #e9e9e9;
}

.tab.active {
  background-color: #004a7c;
  color: #fff;
}

.content {
  border: 1px solid #ccc;
}

// borderless-table
.borderless-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 20px;
}

.borderless-table th,
.borderless-table td {
  padding: 8px;
  text-align: left;
}

.borderless-table th {
  background-color: #f2f2f2;
}

.borderless-table td {
  border: none;
}

.details-table {
  margin-bottom: 2rem !important;
}

.custom-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-btn-wrapper .custom-action-button {
  padding-inline: 2rem;
  margin-right: 10px;
}

.custom-btn-wrapper .custom-action-button:last-child {
  margin-right: 0 !important;
}

.invoice-container {
  margin-bottom: 2rem;
}
</style>