<template>
    <div class="table-responsive">
        <table class="custom-table">
            <thead>
                <tr>
                    <th rowspan="2" v-if="invoice_data.length > 0">
                    </th>
                    <th rowspan="2">Transaction Date</th>
                    <th rowspan="2">Txn Type</th>
                    <th rowspan="2">AWB No/Job No</th>
                    <th rowspan="2">Service Type</th>
                    <th rowspan="2">Booking No</th>
                    <th rowspan="2">Invoice Amount</th>
                    <th rowspan="2" v-if="['matched', 'duplicated', 'multiple'].includes(invoice_type)">Booking
                        Cost</th>
                    <th rowspan="2" v-if="['matched', 'duplicated', 'multiple'].includes(invoice_type)">Recon
                        Status</th>
                    <th rowspan="2" v-if="['matched', 'duplicated', 'multiple'].includes(invoice_type)">Client</th>
                    <th colspan="2">Cost Center</th>
                    <th rowspan="2" style="width: 200px;">Comment</th>
                    <th rowspan="2" v-if="invoice_data.length > 0">Action</th>
                </tr>
                <tr>
                    <th>Code</th>
                    <th>Value</th>
                </tr>
            </thead>

            <tbody>
                <template v-if="invoice_data.length > 0">
                    <tr v-for="(invoice, i) in invoice_data"
                        :class="[{ 'error_row': (error_status && error_invoices.includes(invoice.id)) }]">
                        <td>
                            <template v-if="status === 'reconciled'">
                                <template v-if="invoice.is_included === 1">
                                    <Badge type="success">Included</Badge>
                                </template>
                                <template v-if="invoice.is_included === 0">
                                    <Badge type="danger">Dropped</Badge>
                                </template>
                            </template>
                            <template v-else>
                                <RadioButtonGroup :name="`${invoice_type}-invoice-user-selection-${invoice.id}`"
                                    padding="5px 15px" gap="5px" :options="invoice_options" :value="invoice.is_included"
                                    @input-change="(data) => handleUserSelection(data, invoice)" />
                            </template>
                        </td>
                        <td>{{ invoice.invoice_date }}</td>
                        <td>{{ formatLabel(invoice.category) }}</td>
                        <td>
                            <template
                                v-if="invoice_type === 'multiple' || (invoice_type === 'duplicated' && invoice.is_same_invoice_duplicate)">
                                <Badge type="custom" :color="groupedDataColorCodes[invoice.airway_bill_no]">
                                    {{ formatBookingRef(invoice.airway_bill_no, invoice.job_no) }}
                                </Badge>
                            </template>
                            <template v-else>
                                {{ formatBookingRef(invoice.airway_bill_no, invoice.job_no) }}
                            </template>
                        </td>
                        <td>{{ invoice.service_type }}</td>
                        <td>
                            {{ invoice.courier_booking_id ? 'CDBSA' + invoice.courier_booking_id : '--' }}
                        </td>
                        <!-- <td>
                            <ul style="text-align: start;">
                                <template v-if="invoice.extra_charges.length != 0">
                                    <li v-for="(charge, c) in invoice.extra_charges"
                                        :key="`${invoice.invoice_number}-${c}`">
                                        {{ `${capitalizeFirstLetter(charge.name)} : $ ${charge.total}` }}
                                    </li>
                                </template>
                                <template v-else>
                                    <li>No Extra Charges</li>
                                </template>
                            </ul>
                        </td> -->
                        <td>{{ `$ ${invoice.invoice_value_ex_gst}` }}</td>
                        <td v-if="['matched', 'duplicated', 'multiple'].includes(invoice_type)">
                            <template v-if="invoice.booking_cost_ex_gst">
                                {{ `$ ${invoice.booking_cost_ex_gst}` }}
                            </template>
                            <template v-else>
                                <Badge type="danger">
                                    No Booking Found
                                </Badge>
                            </template>
                        </td>
                        <td v-if="['matched', 'duplicated', 'multiple'].includes(invoice_type)">
                            <template v-if="invoice.booking">
                                <ReconStatus v-if="invoice" :booking_cost="invoice.booking_cost_ex_gst"
                                    :invoice_amount="invoice.invoice_value_ex_gst" />
                            </template>
                            <template v-else>
                                -
                            </template>
                        </td>
                        <td v-if="['matched', 'duplicated', 'multiple'].includes(invoice_type)">{{ invoice.booking &&
                            invoice.booking.client.toUpperCase() }}</td>
                        <td>{{ invoice.custom_code }}</td>
                        <td>{{ invoice.custom_code_value }}</td>
                        <td>{{ invoice.reconciliation_comment }}</td>
                        <td class="action-btns">
                            <ActionButton :title="`Show Duplicated Invoices History`"
                                v-if="['duplicated'].includes(invoice_type)" type="secondary"
                                @handle-click="showDuplicatedhistory(invoice)">
                                <i class="material-icons">list</i>
                            </ActionButton>
                            <template
                                v-if="!invoice.is_reconciled && (invoice.is_included && invoice.is_included != 0)">
                                <ActionButton
                                    :title="`${(!invoice.custom_code && !invoice.custom_code_value) ? 'Add' : 'Edit'} Cost Center`"
                                    v-if="['unmatched', 'duplicated', 'multiple'].includes(invoice_type) && !invoice.is_booking_match"
                                    type="warning" @handle-click="manageCostCenter(invoice)">
                                    <i class="material-icons">{{ `${(!invoice.custom_code && !invoice.custom_code_value)
                                        ? 'add_box' : 'rate_review'}` }}</i>
                                </ActionButton>
                                <ActionButton :title="`${(!invoice.reconciliation_comment) ? 'Add' : 'Edit'} Comment`"
                                    type="info" @handle-click="manageReconciliationComment(invoice)">
                                    <i class="material-icons">{{ `${(!invoice.reconciliation_comment) ? 'chat' :
                                        'edit'}` }}</i>
                                </ActionButton>
                            </template>
                        </td>
                    </tr>
                </template>
                <template v-else>
                    <tr>
                        <td :colspan="invoice_type == 'unmatched' ? '10' : '11'">No Invoice Data Found</td>
                    </tr>
                </template>
            </tbody>
        </table>

    </div>
</template>
<script>
import moment from "moment";
import ReconStatus from "./ReconStatus.vue";
import ActionButton from "../components/ActionButton.vue";
import Badge from "../components/Badge.vue";
import RadioButtonGroup from "../components/RadioButtonGroup.vue";

export default {
    name: "DhlInvoiceDataTable",
    props: {
        invoice_data: {
            required: true
        },
        invoice_type: {
            required: true
        },
        checked_invoices: {
            type: Object,
            required: true
        },
        error_status: {
            type: Boolean,
            default: false,
        },
        error_invoices: {
            type: Array,
            default: [],
        },
        status: {
            type: String,
        },
    },
    components: {
        ReconStatus,
        ActionButton,
        RadioButtonGroup,
        Badge
    },
    filters: {
        formatDate(value) {
            if (!value) {
                return;
            }

            return moment(String(value)).format('DD/MM/YYYY')
        }
    },
    data() {
        return {
            invoice_options: [
                { label: 'Include', value: '1', type: 'success' },
                { label: 'Drop', value: '0', type: 'danger' },
            ],
            selected: [],
            groupedDataColorCodes: {}
        };
    },
    created() {

        this.loadSelected();

        if (['multiple', 'duplicated'].includes(this.invoice_type)) {
            this.groupedDataColorCodes = this.invoice_data.reduce((grouped, row) => {
                if (!grouped[row.airway_bill_no]) {
                    grouped[row.airway_bill_no] = this.getRandomDarkColor();
                }
                return grouped;
            }, {});
        }
    },
    mounted() {
        this.$parent.$on('clear-selected', () => {
            this.selected = [];
            this.loadSelected();
        });
    },
    methods: {
        loadSelected() {
            this.selected = [...this.invoice_data.filter((invoice) => {
                if (!invoice.is_reconciled && !invoice.is_included) {
                    return invoice
                }
            }).map((invoice) => invoice.id)];

            this.emitSelected();
        },
        capitalizeFirstLetter(string) {
            if (!string) return string;

            return string
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
        },
        handleSelect() {
            this.emitSelected();
        },
        emitSelected() {
            this.$emit('update-selected', {
                type: this.invoice_type,
                selected: this.selected
            });
        },
        manageCostCenter(invoice) {
            this.$emit('manage-cost-center', {
                ...invoice,
                invoice_type: this.invoice_type,
                shipment_number: this.formatBookingRef(invoice.airway_bill_no, invoice.job_no)
            });
        },
        manageReconciliationComment(invoice) {
            this.$emit('manage-comment-center', {
                ...invoice,
                invoice_type: this.invoice_type,
                shipment_number: this.formatBookingRef(invoice.airway_bill_no, invoice.job_no)
            });
        },
        handleUserSelection(data, invoice) {
            this.$emit('manage-user-selection', {
                user_selection_data: data,
                invoice,
                invoice_type: this.invoice_type
            });
        },
        showDuplicatedhistory(invoice) {
            this.$emit('show-duplicated-history', {
                ...invoice,
                invoice_type: this.invoice_type,
                shipment_number: this.formatBookingRef(invoice.airway_bill_no, invoice.job_no)
            });
        },
        getRandomDarkColor() {
            const r = Math.floor(Math.random() * 100) + 50;
            const g = Math.floor(Math.random() * 100) + 50;
            const b = Math.floor(Math.random() * 100) + 50;

            return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
        },
        formatLabel(value) {
            const labelMap = {
                courier_charge: "Courier Charge",
                booking: "Booking",
                extra_charge: "Extra Charge"
            };
            return labelMap[value] || value;
        },
        formatBookingRef(airwayBillNo, jobNo) {
            if (!airwayBillNo && !jobNo) {
                return '';
            } else if (!airwayBillNo) {
                return jobNo;
            } else if (!jobNo) {
                return airwayBillNo;
            } else if (airwayBillNo === jobNo) {
                return airwayBillNo;
            } else {
                return `${airwayBillNo} / ${jobNo}`;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
// custome-table
.table-responsive {
    overflow-x: auto;
    margin: 20px;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table .error_row {
    background-color: #f8d7da !important;
}

.custom-table .reconciled td {
    background-color: #d1e7dd !important;
    border-color: #A7B99B !important;
    /* Apply border color to td elements */
}

.custom-table th,
.custom-table td {
    padding-inline: 5px;
    padding-block: 12px;
    border: 1px solid #dee2e6;
    text-align: center;
    white-space: nowrap;
    font-size: 15px;
}

.custom-table th {
    background-color: #f8f9fa;
    color: #495057;
}

.custom-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.custom-table tr:hover {
    background-color: #e9ecef;
}

.row-checkbox {
    cursor: pointer;
}

.custom-table .action-btns>.custom-action-button {
    margin-right: 5px;
}

.custom-table .action-btns>.custom-action-button:last-child {
    margin-right: 0 !important;
}
</style>